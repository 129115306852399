/* eslint-disable no-case-declarations */
import {
  DEFAULT_VALUE_COLLABORATION_CONFIG,
  DEFAULT_VALUE_CONTROLBAR_CONFIG,
} from './constants';
import { I_Group, I_Scene, I_Plugin, I_FormCaptureConfig } from './types';

// NOTE: 8 plugins CURRENTLY

export const onGetDetailPlugin = (
  plugins: I_Plugin[] = [],
  key: I_Plugin['title'] = undefined,
) => {
  if (plugins?.length)
    switch (key) {
      case 'map':
        const pluginMap = plugins.filter(item => item.title === 'map')[0];
        const dataMap =
          pluginMap?.title === 'map' ? pluginMap?.config?.data || null : null;
        const configMap =
          pluginMap?.title === 'map' ? pluginMap?.config?.config || null : null;
        return { dataMap, configMap };
      case 'logo':
        const pluginLogo = plugins.filter(item => item.title === 'logo')[0];
        const configLogo =
          pluginLogo?.title === 'logo'
            ? pluginLogo?.config?.config || null
            : null;
        return { configLogo };
      case 'nadir':
        const pluginNadir = plugins.filter(item => item.title === 'nadir')[0];
        const configNadir =
          pluginNadir?.title === 'nadir'
            ? pluginNadir?.config?.config || null
            : null;
        return { configNadir };
      case 'callToAction':
        const pluginCallToAction = plugins.filter(
          item => item.title === 'callToAction',
        )[0];
        const listCallToAction =
          pluginCallToAction?.title === 'callToAction'
            ? pluginCallToAction?.config?.data || []
            : [];
        return { listCallToAction };
      case 'popupIntro':
        const pluginPopupIntro = plugins.filter(
          item => item.title === 'popupIntro',
        )[0];
        const configPopupIntro =
          pluginPopupIntro?.title === 'popupIntro'
            ? pluginPopupIntro?.config?.config || null
            : null;
        return { configPopupIntro };
      case 'floorplan':
        const pluginFloorplan = plugins.filter(
          item => item.title === 'floorplan',
        )[0];
        const listFloorplan =
          pluginFloorplan?.title === 'floorplan'
            ? pluginFloorplan?.config?.data?.filter(
                item => item.name && item.src && item.publish,
              ) || []
            : [];
        const configFloorplan =
          pluginFloorplan?.title === 'floorplan'
            ? pluginFloorplan?.config?.config || null
            : null;
        return { listFloorplan, configFloorplan };
      case 'formCapture':
        const pluginFormCapture = plugins.filter(
          item => item.title === 'formCapture',
        )[0];
        const listFormCapture =
          pluginFormCapture?.title === 'formCapture'
            ? pluginFormCapture?.config?.data?.filter(item => item.publish) ||
              []
            : [];

        const configFormCapture =
          pluginFormCapture?.title === 'formCapture'
            ? pluginFormCapture?.config?.config || []
            : [];

        const formCaptureConfigOnProject = (() => {
          const data = configFormCapture?.filter(
            item => item.type === 'project' && item.form,
          );
          return data.length ? data[0] : undefined;
        })();
        return {
          listFormCapture,
          configFormCapture,
          formCaptureConfigOnProject,
        };
      case 'protectPassword':
        const pluginProtectPassword = plugins.filter(
          item => item.title === 'protectPassword',
        )[0];
        const configProtectPassword =
          pluginProtectPassword?.title === 'protectPassword'
            ? pluginProtectPassword?.config?.config || null
            : null;
        return { configProtectPassword };
      case 'copyRight':
        const pluginCopyRight = plugins.filter(
          item => item.title === 'copyRight',
        )[0];
        const configCopyRight =
          pluginCopyRight?.title === 'copyRight'
            ? pluginCopyRight?.config?.config || null
            : null;
        return { configCopyRight };
      case 'collaboration':
        const pluginCollaboration = plugins.filter(
          item => item.title === 'collaboration',
        )[0];
        const configCollaboration =
          pluginCollaboration?.title === 'collaboration'
            ? pluginCollaboration?.config?.config ||
              DEFAULT_VALUE_COLLABORATION_CONFIG
            : DEFAULT_VALUE_COLLABORATION_CONFIG;
        return { configCollaboration };
      case 'controlbar':
        const pluginControlbar = plugins.filter(
          item => item.title === 'controlbar',
        )[0];
        const configControlbar =
          pluginControlbar?.title === 'controlbar'
            ? pluginControlbar?.config?.config ||
              DEFAULT_VALUE_CONTROLBAR_CONFIG
            : DEFAULT_VALUE_CONTROLBAR_CONFIG;
        return { configControlbar };
      case 'screenshotCapture':
        const pluginScreenshotCapture = plugins.filter(
          item => item.title === 'screenshotCapture',
        )[0];
        const configScreenshotCapture =
          pluginScreenshotCapture?.title === 'screenshotCapture'
            ? pluginScreenshotCapture?.config?.config || null
            : null;
        return { configScreenshotCapture };
      default:
        break;
    }

  return undefined;
};

export const getScenes = (listSceneBefore: I_Scene[] = []) => {
  const scenes =
    listSceneBefore
      .filter(el => !el.parent)
      .map(item => ({
        ...item,
        staging:
          listSceneBefore
            ?.filter(el => el.parent && el.parent === item.id)
            ?.filter(el => el.mode_in_parent === 'staging')
            // NOTE: Because the array is frozen in strict mode, you'll need to copy the array before sorting it: (https://stackoverflow.com/questions/53420055/error-while-sorting-array-of-objects-cannot-assign-to-read-only-property-2-of/53420326)
            ?.slice()
            ?.sort((a, b) =>
              a.sort_in_parent === b.sort_in_parent
                ? new Date(b.created_at).valueOf() -
                  new Date(a.created_at).valueOf()
                : a.sort_in_parent - b.sort_in_parent,
            ) || [],
        day2dust:
          listSceneBefore
            ?.filter(el => el.parent && el.parent === item.id)
            ?.filter(el => el.mode_in_parent === 'day2dust') || [],
      })) || [];

  const listScene =
    scenes.sort((a, b) =>
      a.sort === b.sort
        ? new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
        : a.sort - b.sort,
    ) || [];

  return listScene;
};

export const getGroups = (scenes = [], groups: I_Group[] = []) => {
  const groupUncategory: I_Group = {
    id: 'uncategory',
    name: 'Uncategory',
    sort: 0,
    scenes: scenes
      .filter(item => !item.group)
      .filter(item => item.publish)
      .sort((a, b) =>
        a.sort === b.sort
          ? new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
          : a.sort - b.sort,
      ),
  };

  const listGroup = [groupUncategory]
    .concat(
      groups.map(item => ({
        ...item,
        scenes:
          scenes
            .filter(scene => scene.group === item.id)
            .filter(scene => scene.publish)
            .sort((a, b) =>
              a.sort === b.sort
                ? new Date(b.created_at).valueOf() -
                  new Date(a.created_at).valueOf()
                : a.sort - b.sort,
            ) || [],
      })),
    )
    .filter(item => item.scenes.length);

  return listGroup;
};

export const onGetHotspotIdsAttachedToFormCaptures = (
  listScene: I_Scene[],
  config: I_FormCaptureConfig[],
) =>
  (
    config
      ?.filter(
        item => item.type === 'hotspot' && item.by === 'id' && item.hotspot_id,
      )
      .map(item =>
        item.type === 'hotspot' &&
        item.by === 'id' &&
        item.hotspot_id &&
        item.form
          ? item.hotspot_id
          : '',
      ) || []
  ).concat(
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'scene' &&
          item.hotspot_scene_id &&
          item.form,
      )
      .map(item =>
        item.type === 'hotspot' && item.by === 'scene' && item.hotspot_scene_id
          ? item.hotspot_scene_id
          : '',
      )
      .map(item => listScene.filter(scene => scene.id === item)[0] || undefined)
      .filter(item => item)
      .reduce((result, item) => {
        result = result.concat(item.hotspots.map(hotspot => hotspot.id));
        return result;
      }, []),
  );

export const onGetFormCapturesAttachedToHotspotIds = (
  listScene: I_Scene[],
  config: I_FormCaptureConfig[],
) =>
  (
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'id' &&
          item.hotspot_id &&
          item.form,
      )
      .map(item =>
        item.type === 'hotspot' && item.by === 'id' && item.hotspot_id
          ? item
          : null,
      ) || []
  ).concat(
    config
      ?.filter(
        item =>
          item.type === 'hotspot' &&
          item.by === 'scene' &&
          item.hotspot_scene_id &&
          item.form,
      )
      .reduce((result, item) => {
        if (item.type === 'hotspot' && item.by === 'scene') {
          const scene =
            listScene.filter(el => el.id === item.hotspot_scene_id)[0] ||
            undefined;
          if (scene) result = result.concat(scene.hotspots.map(() => item));
        }
        return result;
      }, [])
      .map(item => item),
  );

export const onGetHotspotTypesAttachedToFormCaptures = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(
      item =>
        item.type === 'hotspot' &&
        item.by === 'type' &&
        item.hotspot_type &&
        item.form,
    )
    .map(item =>
      item.type === 'hotspot' && item.by === 'type' && item.hotspot_type
        ? item.hotspot_type
        : 'point',
    ) || [];

export const onGetFormCapturesAttachedToHotspotTypes = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(
      item =>
        item.type === 'hotspot' &&
        item.by === 'type' &&
        item.hotspot_type &&
        item.form,
    )
    .map(item =>
      item.type === 'hotspot' && item.by === 'type' && item.hotspot_type
        ? item
        : null,
    )
    .map(item => item) || [];

export const onGetSceneIdsAttachedToFormCaptures = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(item => item.type === 'scene' && item.scene_id && item.form)
    .map(item =>
      item.type === 'scene' && item.scene_id ? item.scene_id : '',
    ) || [];

export const onGetFormCapturesAttachedToSceneIds = (
  config: I_FormCaptureConfig[],
) =>
  config
    ?.filter(item => item.type === 'scene' && item.scene_id && item.form)
    .map(item => (item.type === 'scene' && item.scene_id ? item : null))
    .map(item => item) || [];
